import {
  addDocument,
  deleteDocument,
  setCollection,
  setDocument,
  subscribeToCollection,
} from "./databaseUtils";
import { orderBy, query } from "firebase/firestore";

// Function to create a new file

export const addPdfRunFile = async ({
  tool_name,
  customer_id,
  vehicle_id,
  additional_instructions,
  status,
  run_datetime,
  user_name,
  user_id,
  target_pdf,
  completed_pdf,
}) => {
  const newRunFile = {
    customer_id,
    vehicle_id,
    additional_instructions,
    status,
    run_datetime,
    user_name,
    user_id,
    target_pdf,
    completed_pdf,
  };

  const collectionPath = ["customers", customer_id, "tools", tool_name, "runs"];

  const result = await addDocument(collectionPath, newRunFile);
  return result ? { id: result.id } : null;
};

export const deleteFile = async (companyId, fileId) => {
  await deleteDocument([
    "customers",
    companyId,
    "tools",
    "AvaPDF",
    "files",
    fileId,
  ]);
};

export const setRunStatusFailed = async (companyId, runId) => {
  const collectionPath = ["customers", companyId, "tools", "PDF", "runs"];
  const data = {
    status: "Failed",
    result: "",
  };
  const options = { merge: true };

  return await setDocument(collectionPath, runId, data, options);
};

export const queryRuns = (companyId) =>
  query(
    setCollection(["customers", companyId, "tools", "AvaPDF", "runs"]),
    orderBy("run_datetime", "desc"),
  );

export const subscribeToAvaPdfRuns = (companyId, onUpdate, onError) =>
  subscribeToCollection(queryRuns(companyId), onUpdate, onError);
