import { useState, useEffect } from "react";
import { useColorMode } from "../../theme";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Button,
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebaseConfig";
import {
  collection,
  getDocs,
  addDoc,
  query,
  updateDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";

import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const actionIcons = {
  leaveFeedback: { tooltip: "Leave Feedback", icon: <ReviewsOutlinedIcon /> },
  retryProcess: { tooltip: "Retry Process", icon: <ReplayOutlinedIcon /> },
  cancelAction: { tooltip: "Cancel Action", icon: <CancelOutlinedIcon /> },
};

const AutomatedTasks = () => {
  const { theme } = useColorMode();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ action: "", row: {} });
  const [inputValue, setInputValue] = useState("");
  const [statusStepsModalOpen, setStatusStepsModalOpen] = useState(false);
  const [statusStepsContent, setStatusStepsContent] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useUser();

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "customers", user.companyId, "tasks"),
            orderBy("dateStarted", "desc"),
          ),
        );
        const actionsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const dateStarted =
            data.dateStarted instanceof Date
              ? data.dateStarted.toLocaleDateString()
              : data.dateStarted;
          return { id: doc.id, ...data, dateStarted: dateStarted };
        });
        console.log("Fetched task data:", actionsData);
        setRows(actionsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tasks: ", error);
        setLoading(false);
      }
    };

    if (user.companyId) {
      fetchActions();
    }
  }, [user.companyId]);

  const handleActionClick = async (action, row) => {
    if (action === "retryProcess") {
      try {
        await fetch("http://your-flask-backend-ip/retry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: row.id,
            name: row.task,
            status: row.status.text,
            date: row.dateStarted,
          }),
        });
        alert("Process retried successfully!");
      } catch (error) {
        console.error("Error retrying process: ", error);
        alert("Failed to retry process.");
      }
    } else if (action === "cancelAction") {
      try {
        const taskDocRef = doc(
          db,
          "customers",
          user.companyId,
          "tasks",
          row.id,
        );
        await updateDoc(taskDocRef, {
          status: { dotColor: "red", text: "Cancelled" },
        });
        alert("Process cancelled successfully!");
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id
              ? { ...r, status: { dotColor: "red", text: "Cancelled" } }
              : r,
          ),
        );
      } catch (error) {
        console.error("Error cancelling process: ", error);
        alert("Failed to cancel process.");
      }
    } else {
      setModalContent({ action, row });
      setOpen(true);
    }
  };

  const handleStatusClick = (statusSteps) => {
    if (Array.isArray(statusSteps)) {
      setStatusStepsContent(statusSteps);
    } else {
      setStatusStepsContent([statusSteps]);
    }
    setActiveStep(0);
    setStatusStepsModalOpen(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const columns = [
    { field: "task", headerName: "Task", width: 180 },
    { field: "description", headerName: "Description", width: 350 },
    { field: "vehicle", headerName: "Vehicle", width: 150 },
    { field: "dateStarted", headerName: "Date Started", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="10px">
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: params.value.dotColor,
              borderRadius: "50%",
              display: "inline-block",
              marginRight: 1,
            }}
          />
          {params.row.statusSteps ? (
            <Typography
              variant="body2"
              onClick={() => handleStatusClick(params.row.statusSteps)}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {params.value.text}
            </Typography>
          ) : (
            <Typography variant="body2">{params.value.text}</Typography>
          )}
        </Box>
      ),
    },
    {
      field: "moreActions",
      headerName: "More Actions",
      width: 180,
      renderCell: (params) => (
        <Box display="flex">
          {Object.keys(actionIcons).map((action) => {
            const buttonEnabled =
              action !== "retryProcess" ||
              ["failed", "cancelled"].some((status) =>
                params.row.status.text.toLowerCase().includes(status),
              );

            return (
              <Tooltip key={action} title={actionIcons[action].tooltip}>
                <span>
                  {" "}
                  {/* Wrapping in a span to handle disabled tooltips correctly */}
                  <IconButton
                    onClick={() => handleActionClick(action, params.row)}
                    disabled={!buttonEnabled}
                  >
                    {actionIcons[action].icon}
                  </IconButton>
                </span>
              </Tooltip>
            );
          })}
        </Box>
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const handleStatusStepsModalClose = () => {
    setStatusStepsModalOpen(false);
    setStatusStepsContent([]);
    setActiveStep(0);
  };

  const handleSubmit = async () => {
    const { action, row } = modalContent;

    if (action === "leaveFeedback") {
      try {
        const taskDocRef = doc(
          db,
          "customers",
          user.companyId,
          "tasks",
          row.id,
        );
        await updateDoc(taskDocRef, {
          feedback: inputValue,
        });
        alert("Feedback submitted successfully!");
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id ? { ...r, feedback: inputValue } : r,
          ),
        );
      } catch (error) {
        console.error("Error submitting feedback: ", error);
        alert("Failed to submit feedback.");
      }
    }

    handleClose();
  };

  const sortFields = (obj) => {
    const priority = ["step", "name", "tool"];
    const orderedFields = {};

    // Add priority fields first
    priority.forEach((field) => {
      if (field in obj) {
        orderedFields[field] = obj[field];
      }
    });

    // Add remaining fields in alphabetical order
    Object.keys(obj)
      .sort()
      .forEach((key) => {
        if (!priority.includes(key)) {
          orderedFields[key] = obj[key];
        }
      });

    return orderedFields;
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
  };

  const iterationHeaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: 2,
    mb: 2,
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            disableRowSelectionOnClick={true}
            loading={loading}
            autoHeight
          />
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600, // Increased width
            maxHeight: "90vh", // Maximum height
            overflowY: "auto", // Vertical scroll
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            {modalContent.action === "leaveFeedback"
              ? "Leave Feedback"
              : "Report Bug"}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter your feedback"
            sx={{ my: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
      <Modal open={statusStepsModalOpen} onClose={handleStatusStepsModalClose}>
        <Box sx={modalStyle}>
          <Box sx={headerStyle}>
            <Typography variant="h6" id="modal-modal-title">
              Status Steps
            </Typography>
            <IconButton onClick={handleStatusStepsModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {statusStepsContent.length > 0 && (
            <Box>
              <Box sx={iterationHeaderStyle}>
                <IconButton onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                </IconButton>
                <Typography variant="h6" sx={{ mx: 2 }}>
                  Step {activeStep + 1} / {statusStepsContent.length}
                </Typography>
                <IconButton
                  onClick={handleNext}
                  disabled={activeStep === statusStepsContent.length - 1}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Box>
              <Table>
                <TableBody>
                  {Object.entries(
                    sortFields(statusStepsContent[activeStep]),
                  ).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{JSON.stringify(value, null, 2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default AutomatedTasks;
