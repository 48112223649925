import React, { useState, useEffect } from "react";
import { useColorMode } from "../../theme";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebaseConfig";
import {
  doc,
  collection,
  query,
  setDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { calculateVehicleInfo, formatDateToYYYYMMDD } from "./vehicleUtils";

const actionMapping = {
  startRegistrationRenewal: {
    tooltip: "Start Registration Renewal",
    icon: <DescriptionOutlinedIcon />,
    description: "Start the vehicle registration renewal process.",
  },
  insuranceDocumentation: {
    tooltip: "Request for new Insurance Documentation",
    icon: <VerifiedUserOutlinedIcon />,
    description: "Request updated insurance documents.",
  },
  scheduleInspection: {
    tooltip: "Schedule Inspection",
    icon: <BuildOutlinedIcon />,
    description: "Schedule a vehicle inspection.",
  },
  emissionTest: {
    tooltip: "Schedule Emission Inspection",
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABbUlEQVR4nOWUu0oDYRCFv0KsjJWCiRpBRVux1jaFCmrh5QlsNdqpZR4g4FMYbbSw04CkEbGJhZZiZ/ACXgvxwsBZWDaTSLLY6MAPZ2fOnG9hL/BfagYoAs86h8BEs2GDwA5QAe6BE+CrxinJY94CMPBTuBnunKBXYB1I62wAb47PdvvrAXZl3AOSQAqYBnodb1qzLqAb2Nfudj1AcPcW3miltHtbz/Tw24AjmeabACxq96CWwUI/gQ9gpAnAqHbtzHqGc93BaqTfF9LB2+LNrNY0L3uAFw0Tod5Z5JmEAUlp8wTVrp5lVdWphva+t6pXVC/jADLS5kE7m+rZx1lVU6GAJfW2dJ11AFlp86CdYF7zNzIpek7XK1rIO4C89LKuc9pt6B+1EPk6w4CC9Bwxalwhxw6gJD0WBzCskAsHcCk9FAeQVsiVA7iW7okD6FTIjQOoSHfEASQU8ugAnqTb4gBaFPLuAKxn2jx/uL4BMuuOt+8Tp5wAAAAASUVORK5CYII=" />
    ),
    description: "Schedule an emission inspection.",
  },
  weightTest: {
    tooltip: "Schedule Weight Test",
    icon: <ScaleOutlinedIcon />,
    description: "Schedule a vehicle weight test.",
  },
  updateInformation: {
    tooltip: "Update Information",
    icon: <EditOutlinedIcon />,
    description: "Update vehicle information.",
  },
};

const ThreeDotMenu = ({ options, onOptionClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    onOptionClick(option);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const Compliance = () => {
  const { theme } = useColorMode();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskRunning, setTaskRunning] = useState(false);
  const navigate = useNavigate();
  const { user, company, backendUrl } = useUser();

  const handleAddVehicle = () => {
    navigate(`/vehicle/new`);
  };

  const handleIconClick = (vehicle, task) => {
    if (task.status === "running") {
      setTaskRunning(true);
    } else {
      setSelectedVehicle(vehicle);
      setSelectedTask(task);
      setTaskRunning(false);
    }
    setTaskDialogOpen(true);
  };

  const handleTaskConfirm = async () => {
    if (!selectedTask) return;
    try {
      const tasksCollectionRef = collection(
        db,
        "customers",
        user.companyId,
        "tasks",
      );

      const formattedDate = formatDateToYYYYMMDD(new Date());

      // Add task to Firestore
      const taskDocRef = await addDoc(tasksCollectionRef, {
        dateStarted: formattedDate,
        task: selectedTask.tooltip,
        vehicle: selectedVehicle.carName,
        vehicleID: selectedVehicle.vin,
        description: selectedTask.description,
        status: {
          dotColor: "yellow",
          text: "Task Queued",
        },
      });

      console.log("Task created successfully with ID:", taskDocRef.id);

      // Define endpoint URL based on task
      let endpoint = "";
      if (selectedTask.tooltip === "Start Registration Renewal") {
        endpoint = `${backendUrl}/tasks/start-registration-renewal`;
      } else if (selectedTask.tooltip === "Schedule Inspection") {
        endpoint = `${backendUrl}/tasks/schedule-inspection`;
      }

      // Send POST request with task ID
      if (endpoint) {
        fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customer_id: user.companyId,
            user_id: user.uid,
            vehicle_id: selectedVehicle.vin,
            task: selectedTask.tooltip,
            task_id: taskDocRef.id, // Include task ID in the request
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Task initiated successfully:", data);
            // Handle success response
          })
          .catch((error) => {
            console.error("Error initiating task:", error);
            // Handle error response
          });
      }
    } catch (error) {
      console.error("Error creating task:", error);
    }
    setTaskDialogOpen(false);
    setSelectedVehicle(null);
    setSelectedTask(null);
  };

  const handleTaskCancel = () => {
    setTaskDialogOpen(false);
    setSelectedVehicle(null);
    setSelectedTask(null);
  };

  const columns = [
    {
      field: "carImage",
      headerName: "",
      width: 80,
      renderCell: (params) => (
        <Box
          component="svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 361.000000 227.000000"
          preserveAspectRatio="xMidYMid meet"
          sx={{ width: 50, height: 30, mt: 1 }}
        >
          <g
            transform="translate(0.000000,227.000000) scale(0.100000,-0.100000)"
            fill="#fff"
            stroke="none"
          >
            <path d="M210 1310 l0 -770 -28 0 c-40 0 -52 -17 -52 -73 0 -85 3 -87 144 -87 l123 0 12 58 c20 100 94 192 190 235 69 32 179 29 252 -5 67 -31 128 -89 155 -146 10 -23 22 -42 25 -42 3 0 14 18 24 39 25 56 91 120 155 150 74 34 182 35 255 3 97 -44 175 -146 191 -249 l6 -43 468 0 467 0 7 47 c14 103 84 195 185 241 73 34 183 37 252 5 96 -43 170 -135 190 -235 l12 -58 128 0 c146 0 149 2 149 82 0 49 -15 75 -45 80 -19 3 -20 13 -25 213 -5 198 -6 212 -28 247 -43 69 -91 100 -220 142 l-119 39 -98 185 c-109 206 -134 239 -203 273 -45 23 -57 24 -314 27 l-268 3 0 -581 0 -581 -37 3 -38 3 -3 783 -2 782 -955 0 -955 0 0 -770z m2518 226 c15 -8 37 -25 48 -38 23 -25 194 -348 194 -366 0 -6 -7 -18 -16 -26 -12 -13 -40 -16 -142 -16 -70 0 -132 4 -137 8 -6 4 -23 24 -38 45 -44 58 -70 67 -202 67 l-115 0 0 170 0 170 190 0 c145 0 198 -3 218 -14z" />
            <path d="M651 629 c-155 -45 -234 -225 -162 -368 26 -51 100 -114 149 -129 175 -53 341 66 342 243 0 172 -166 300 -329 254z m147 -139 c59 -36 78 -118 42 -178 -41 -67 -124 -91 -187 -52 -80 49 -89 156 -17 219 28 25 43 31 82 31 29 0 59 -8 80 -20z" />
            <path d="M1260 625 c-25 -8 -65 -31 -88 -51 -181 -155 -74 -455 162 -454 108 1 183 43 230 130 58 107 42 225 -44 311 -72 72 -162 94 -260 64z m155 -135 c40 -25 69 -90 60 -136 -17 -92 -124 -142 -202 -94 -122 74 -76 250 66 250 26 0 57 -8 76 -20z" />
            <path d="M2832 621 c-164 -57 -225 -263 -119 -403 88 -116 263 -132 373 -35 83 73 110 166 80 271 -23 77 -88 144 -165 169 -67 21 -102 21 -169 -2z m172 -142 c58 -50 67 -120 24 -182 -57 -81 -174 -73 -228 15 -24 40 -25 79 -3 125 22 47 66 73 125 73 39 0 54 -6 82 -31z" />
          </g>
        </Box>
      ),
    },
    { field: "carName", headerName: "Car Name", width: 130 },
    {
      field: "groupName",
      headerName: "Group Name",
      width: 130,
      filterable: true,
    },
    { field: "licensePlate", headerName: "License Plate", width: 130 },
    { field: "vin", headerName: "VIN", width: 130 },
    { field: "nextRenewalDate", headerName: "Next Renewal Date", width: 160 },
    { field: "criticalItem", headerName: "Critical Item", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: params.value.dotColor,
              borderRadius: "50%",
              display: "inline-block",
              marginRight: 1,
            }}
          />
          <Typography variant="body2">{params.value.text}</Typography>
        </Box>
      ),
    },
    {
      field: "recommendedActions",
      headerName: "Start Task",
      width: 200,
      renderCell: (params) => {
        const actions = params.value;
        const status = params.row.status.text;

        if (status === "Missing Documentation") {
          return (
            <Box display="flex" alignItems="center" mt="10px">
              <Tooltip title={actionMapping["updateInformation"].tooltip}>
                <IconButton
                  sx={{
                    backgroundColor: "warning.main",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "warning.dark",
                    },
                    border: "1px solid",
                    borderRadius: 1,
                    padding: "4px",
                  }}
                  onClick={() =>
                    handleIconClick(
                      params.row,
                      actionMapping["updateInformation"],
                    )
                  }
                >
                  {actionMapping["updateInformation"].icon}
                </IconButton>
              </Tooltip>
            </Box>
          );
        }

        if (actions.length === 0) {
          return (
            <Box display="flex" alignItems="center" mt="10px">
              <Tooltip title="Everything done. Vehicle compliant.">
                <IconButton
                  sx={{
                    backgroundColor: "success.dark",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "success.dark",
                    },
                    border: "1px solid",
                    borderRadius: 1,
                    padding: "4px",
                  }}
                >
                  <DoneOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }

        return (
          <Box display="flex" alignItems="center" mt="10px">
            {actions.map((action, index) => (
              <Tooltip key={action} title={actionMapping[action].tooltip}>
                <IconButton
                  sx={{
                    backgroundColor: "primary.main",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                    border: "1px solid",
                    borderRadius: 1,
                    padding: "4px",
                    marginRight: index !== actions.length - 1 ? 1 : 0,
                  }}
                  onClick={() =>
                    handleIconClick(params.row, actionMapping[action])
                  }
                >
                  {actionMapping[action].icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        );
      },
    },
    {
      field: "options",
      headerName: "",
      width: 5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const options =
          params.row.status === "Archived"
            ? ["Unarchive", "View", "Delete"]
            : ["Archive", "View", "Delete"];
        const handleOptionClick = async (option) => {
          const vehicleDocRef = doc(
            db,
            "customers",
            user.companyId,
            "vehicles",
            params.row.vin,
          );
          if (option === "View") {
            navigate(`/vehicle/${params.row.vin}`);
          } else if (option === "Archive") {
            await updateDoc(vehicleDocRef, {
              prevStatus: params.row.status,
              status: "Archived",
              options: ["Unarchive", "View", "Delete"],
            })
              .then(() => {
                console.log("Document successfully updated!");
                setRows((prevRows) =>
                  prevRows.filter((row) => row.vin !== params.row.vin),
                );
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
              });
          } else if (option === "Unarchive") {
            await updateDoc(vehicleDocRef, {
              status: params.row.prevStatus,
              options: ["Archive", "View", "Delete"],
            })
              .then(() => {
                console.log("Document successfully updated!");
                setRows((prevRows) =>
                  prevRows.filter((row) => row.vin !== params.row.vin),
                );
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
              });
          } else if (option === "Delete") {
            setSelectedVehicle(params.row);
            setDeleteDialogOpen(true);
          }
        };

        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <ThreeDotMenu options={options} onOptionClick={handleOptionClick} />
          </Box>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    if (selectedVehicle) {
      try {
        const vehicleDocRef = doc(
          db,
          "customers",
          user.companyId,
          "vehicles",
          selectedVehicle.vin,
        );
        await deleteDoc(vehicleDocRef);
        setRows((prevRows) =>
          prevRows.filter((row) => row.vin !== selectedVehicle.vin),
        );
        setSelectedVehicle(null);
        setDeleteDialogOpen(false);
      } catch (error) {
        console.error("Error deleting vehicle: ", error);
      }
    }
  };

  const handleDeleteCancel = () => {
    setSelectedVehicle(null);
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const vehiclesCollection = collection(
          db,
          "customers",
          user.companyId,
          "vehicles",
        );
        const vehiclesSnapshot = await getDocs(vehiclesCollection);
        const vehiclesList = vehiclesSnapshot.docs.map((doc) => {
          const vehicleData = doc.data();
          const vehicleInfo = calculateVehicleInfo(vehicleData);
          return {
            id: doc.id,
            ...vehicleData,
            status: vehicleInfo.status,
            nextRenewalDate: vehicleInfo.nextDate,
            criticalItem: vehicleInfo.criticalItem,
            recommendedActions: vehicleInfo.recommendedActions,
          };
        });
        const filterArchived =
          window.location.pathname === "/vehicles/archived";
        const filteredVehiclesList = filterArchived
          ? vehiclesList.filter((vehicle) => vehicle.status.text === "Archived")
          : vehiclesList.filter(
              (vehicle) => vehicle.status.text !== "Archived",
            );
        setRows(filteredVehiclesList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vehicles: ", error);
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [user.companyId]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              minHeight: 400,
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
              paddingBottom: "0px",
              "&::-webkit-scrollbar": {
                width: "12px",
                height: "12px",
              },
              "&::-webkit-scrollbar-track": {
                background: theme.palette.background.secondary,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.background.primary,
                borderRadius: "10px",
                border: `3px solid ${theme.palette.background.secondary}`,
              },
              "& *": {
                scrollbarWidth: "thin",
                scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              loading={loading}
              autoHeight
              disableRowSelectionOnClick={true}
              sx={{
                "& .MuiDataGrid-row": {
                  minHeight: "50px",
                },
              }}
            />
          </Box>
        </Grid>
        {isMobile && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", mt: -1 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddVehicle}
              sx={{
                height: "56px",
                minWidth: "100px",
                padding: "0 16px",
              }}
            >
              + VEHICLE
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this vehicle?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={taskDialogOpen}
        onClose={handleTaskCancel}
        aria-labelledby="task-dialog-title"
        aria-describedby="task-dialog-description"
      >
        <DialogTitle id="task-dialog-title">
          {taskRunning ? "Task Already Running" : "Confirm Task Start"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="task-dialog-description">
            {taskRunning
              ? "This task is already running."
              : selectedTask &&
                `Are you sure you want to start the automated task for ${selectedTask.tooltip}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!taskRunning && (
            <Button onClick={handleTaskCancel} color="primary">
              Cancel
            </Button>
          )}
          <Button
            onClick={taskRunning ? handleTaskCancel : handleTaskConfirm}
            color="primary"
            autoFocus
          >
            {taskRunning ? "Close" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Compliance;
