import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useColorMode } from "../../theme";
import { db } from "../../firebaseConfig";
import {
  collection,
  addDoc,
  onSnapshot,
  setDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";
import AvaWebRunModal from "./avaWebRunModal"; // Import the new component
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import AvaToolFeedbackModal from "./AvaToolFeedbackModal";

const AvaWeb = () => {
  const { theme } = useColorMode();
  const { user, company, backendUrl } = useUser();
  const [avaWebRuns, setAvaWebRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRun, setSelectedRun] = useState(null);
  const [open, setOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    web: "",
    ques: "",
    max_iterations: 10,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getStatusColor = (status) => {
    switch (status) {
      case "Done Successfully":
        return "#00FF00";
      case "Failed":
        return "#FF0000";
      case "Error":
        return "#FF0000";
      case "In Progress":
      case "Created":
        return "#FFFF00";
      default:
        return "#000000";
    }
  };

  const actionIcons = {
    leaveFeedback: { tooltip: "Leave Feedback", icon: <ReviewsOutlinedIcon /> },
  };

  // Add a last column "# Iterations"
  // It should be the length of .iteration / .max_iterations
  const avaWebRunsColumns = [
    {
      field: "view",
      headerName: "View",
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleView(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: getStatusColor(params.value),
              borderRadius: "50%",
              display: "inline-block",
              marginRight: 1,
            }}
          />
          <Typography variant="body2">{params.value}</Typography>
        </Box>
      ),
    },
    { field: "run_datetime", headerName: "Run Time", width: 200 },
    { field: "user_name", headerName: "User Name", width: 130 },
    { field: "web_name", headerName: "Web Name", width: 130 },
    { field: "ques", headerName: "Question", width: 350 },
    { field: "result", headerName: "Result", width: 350 },
    {
      field: "iterations",
      headerName: "# Iterations",
      width: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Typography variant="body2">
            {params.row.iteration
              ? `${params.row.iteration.length}/${params.row.max_iterations}`
              : `0/${params.row.max_iterations}`}
          </Typography>
        </Box>
      ),
    },
    {
      field: "moreActions",
      headerName: "More Actions",
      width: 180,
      renderCell: (params) => (
        <Box display="flex">
          {Object.keys(actionIcons).map((action) => (
            <Tooltip key={action} title={actionIcons[action].tooltip}>
              <IconButton onClick={() => handleActionView(action, params.row)}>
                {actionIcons[action].icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (!company) return;

    // Order by run_datetime desc
    const runsCollection = collection(
      db,
      "customers",
      company.id,
      "tools",
      "AvaWeb",
      "runs",
    );
    const q = query(runsCollection, orderBy("run_datetime", "desc"));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const runsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          run_datetime: new Date(
            doc.data().run_datetime.seconds * 1000,
          ).toLocaleString(),
        }));
        setAvaWebRuns(runsList);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [company]);

  const handleActionView = (action, row) => {
    switch (action) {
      case "leaveFeedback":
        setSelectedRun(row);
        setFeedbackModalOpen(true);
        break;

      default:
        console.error(`Action not implemented: ${action}`);
        alert("Action not implemented");
    }
  };

  const handleFeedbackCloseModal = () => {
    setFeedbackModalOpen(false);
    setSelectedRun(null);
  };

  const handleView = (run) => {
    setSelectedRun(run);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRun(null);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setFormData({ web: "", ques: "", max_iterations: 10 });
  };

  const extractDomain = (url) => {
    const domain = new URL(url).hostname.replace("www.", "");
    return domain;
  };

  const handleAddAvaWebRun = async () => {
    setIsSubmitting(true);

    const runData = {
      web_name: extractDomain(formData.web),
      ques: formData.ques,
      status: "Created",
      max_iterations: formData.max_iterations,
      run_datetime: new Date(),
      user_name: user.name,
      user_id: user.uid,
      result: null,
    };
    const runDocRef = await addDoc(
      collection(db, "customers", company.id, "tools", "AvaWeb", "runs"),
      runData,
    );
    try {
      const webAvaWebEndpoint = `${backendUrl}/tools/avaweb`;
      console.log(webAvaWebEndpoint);

      fetch(webAvaWebEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer_id: company.id,
          user_id: user.uid,
          max_iterations: formData.max_iterations,
          web_name: extractDomain(formData.web),
          tool_run_id: runDocRef.id,
          ques: formData.ques,
          web: formData.web,
          id: `${extractDomain(formData.web)}-${Math.floor(10000 + Math.random() * 90000)}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          alert(`Success: ${data.message}`);
        })
        .catch((error) => {
          console.error("Error processing request: ", error);
          setDoc(
            doc(
              db,
              "customers",
              company.id,
              "tools",
              "AvaWeb",
              "runs",
              runDocRef.id,
            ),
            {
              status: "Failed",
              result: error,
            },
            { merge: true },
          );
        });
      setTimeout(() => {
        setFormData({ web: "", ques: "", max_iterations: 10 });
        setIsSubmitting(false);
        setFormOpen(false);
      }, 1000);
    } catch (error) {
      console.error("Error adding run: ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "max_iterations" ? Number(value) : value,
    }));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Runs</Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setFormOpen(true)}
            >
              Add
            </Button>
          </Box>
          <Box
            sx={{
              minHeight: 400,
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
              paddingBottom: "0px",
              "&::-webkit-scrollbar": {
                width: "12px",
                height: "12px",
              },
              "&::-webkit-scrollbar-track": {
                background: theme.palette.background.secondary,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.background.primary,
                borderRadius: "10px",
                border: `3px solid ${theme.palette.background.secondary}`,
              },
              "& *": {
                scrollbarWidth: "thin",
                scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
              },
            }}
          >
            <DataGrid
              rows={avaWebRuns}
              columns={avaWebRunsColumns}
              pageSize={20}
              loading={loading}
              disableRowSelectionOnClick={true}
              autoHeight
            />
          </Box>
        </Grid>
      </Grid>
      <AvaWebRunModal
        open={open}
        handleClose={handleClose}
        selectedRun={selectedRun}
      />

      <Modal open={formOpen} onClose={handleFormClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Add AvaWeb Run</Typography>
            <IconButton onClick={handleFormClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleAddAvaWebRun();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Web"
                  name="web"
                  value={formData.web}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Max Iterations"
                  name="max_iterations"
                  value={formData.max_iterations}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Question"
                  name="ques"
                  value={formData.ques}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={5}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2 }}
                    disabled={isSubmitting}
                  >
                    Add Run
                  </Button>
                  {isSubmitting && (
                    <CircularProgress size={24} sx={{ ml: 2 }} />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <AvaToolFeedbackModal
        open={feedbackModalOpen}
        onClose={handleFeedbackCloseModal}
        tool="AvaWeb"
        selectedRun={selectedRun}
      />
    </Box>
  );
};

export default AvaWeb;
