import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { db } from "../../firebaseConfig";
import { updateDoc, doc } from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";

const AvaToolFeedbackModal = ({ open, onClose, tool, selectedRun }) => {
  const { backendUrl, company } = useUser();
  const [wasCorrect, setWasCorrect] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [additionalFeedback, setAdditionalFeedback] = useState("");

  const onCloseWrapper = () => {
    onClose();
    setWasCorrect("");
    setCorrectAnswer("");
    setRejectionReason("");
    setAdditionalFeedback("");
  };

  const handleFeedbackSubmit = async () => {
    try {
      // First operation: Send feedback to your backend
      const feedbackEndpoint = `${backendUrl}/feedback`;
      await fetch(feedbackEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer: company.id,
          tool,
          tool_run: selectedRun.id,
          trace_run_id: selectedRun.trace_run_id,
          key: "user_feedback",
          score: wasCorrect === "no" ? 0.0 : 1.0,
          correction:
            wasCorrect === "yes"
              ? null
              : {
                  correct_answer: correctAnswer,
                  rejection_reason: rejectionReason || null,
                },
          comment: additionalFeedback || null,
        }),
      });

      // Second operation: Update Firestore document
      const toolRunDocRef = doc(
        db,
        "customers",
        company.id,
        "tools",
        tool,
        "runs",
        selectedRun.id,
      );
      await updateDoc(toolRunDocRef, {
        feedback: {
          wasCorrect,
          correctAnswer: wasCorrect === "no" ? correctAnswer : null,
          rejectionReason:
            wasCorrect === "no" && correctAnswer === "rejected"
              ? rejectionReason
              : null,
          additionalFeedback,
        },
      });

      // Single success notification
      alert("Feedback submitted successfully!");
    } catch (error) {
      console.error(`Error submitting feedback: ${error}`);
      alert("Failed to submit feedback.");
    } finally {
      onCloseWrapper();
    }
  };

  const isSubmitDisabled = () => {
    if (wasCorrect === "") return true;
    if (wasCorrect === "no") {
      if (correctAnswer === "") return true;
      if (correctAnswer === "rejected" && rejectionReason === "") return true;
    }
    return false;
  };

  return (
    <Modal open={open} onClose={onCloseWrapper}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" id="modal-modal-title">
            Leave Feedback
          </Typography>
          <IconButton onClick={onCloseWrapper}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend">Was the answer correct?</FormLabel>
            <RadioGroup
              row
              value={wasCorrect}
              onChange={(e) => setWasCorrect(e.target.value)}
            >
              <FormControlLabel
                value="yes"
                style={{ marginLeft: 0 }}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {wasCorrect === "yes" && (
            <TextField
              fullWidth
              multiline
              rows={2}
              value={additionalFeedback}
              onChange={(e) => setAdditionalFeedback(e.target.value)}
              placeholder="Anything else to add?"
              sx={{ mb: 2 }}
            />
          )}
          {wasCorrect === "no" && (
            <>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel>What would be the correct answer?</FormLabel>
                <Select
                  value={correctAnswer}
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                >
                  <MenuItem value="approved">Case Approved</MenuItem>
                  <MenuItem value="rejected">Case Rejected</MenuItem>
                </Select>
              </FormControl>
              {correctAnswer === "rejected" && (
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  placeholder="Reasons for rejection"
                  sx={{ mb: 2 }}
                />
              )}
              <TextField
                fullWidth
                multiline
                rows={2}
                value={additionalFeedback}
                onChange={(e) => setAdditionalFeedback(e.target.value)}
                placeholder="Anything else to add?"
                sx={{ mb: 2 }}
              />
            </>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFeedbackSubmit}
          disabled={isSubmitDisabled()}
          sx={{ mb: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default AvaToolFeedbackModal;
