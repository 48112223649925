import { useState, useEffect } from "react";
import { useColorMode } from "../../theme";
import {
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebaseConfig";
import { collection, getDocs, query, deleteDoc, doc } from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";

const DriversView = () => {
  const { theme } = useColorMode();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();

  const handleAddDriver = () => {
    navigate(`/edit-driver/new`);
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const driversSnapshot = await getDocs(
          query(collection(db, "customers", user.companyId, "drivers")),
        );
        const vehiclesSnapshot = await getDocs(
          query(collection(db, "customers", user.companyId, "vehicles")),
        );
        const vehiclesData = vehiclesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const driversData = driversSnapshot.docs.map((doc) => {
          const driverData = { id: doc.id, ...doc.data() };
          const driverVehicles = vehiclesData.filter(
            (vehicle) => vehicle.driverID === driverData.id,
          );
          driverData.vehicles = driverVehicles.map((vehicle) => ({
            name: vehicle.carName,
            make: vehicle.specification?.make,
            model: vehicle.specification?.model,
            year: vehicle.specification?.year,
          }));
          return driverData;
        });

        console.log("Fetched drivers data:", driversData);
        setRows(driversData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
        setLoading(false);
      }
    };

    fetchDrivers();
  }, [user.companyId]);

  const handleEditClick = (row) => {
    navigate(`/edit-driver/${row.id}`);
  };

  const handleDeleteClick = (row) => {
    setSelectedDriver(row);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedDriver) {
      try {
        await deleteDoc(
          doc(db, "customers", user.companyId, "drivers", selectedDriver.id),
        );
        setRows(rows.filter((row) => row.id !== selectedDriver.id));
        setDeleteDialogOpen(false);
        setSelectedDriver(null);
      } catch (error) {
        console.error("Error deleting driver: ", error);
      }
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 160 },
    { field: "phone", headerName: "Phone", width: 120 },
    {
      field: "vehicles",
      headerName: "Vehicle(s)",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          {params.value.map((vehicle, index) => (
            <Typography key={index} variant="body2">
              {vehicle.name} ({vehicle.year} {vehicle.make} {vehicle.model})
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Edit Driver">
          <IconButton onClick={() => handleEditClick(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Delete Driver">
          <IconButton onClick={() => handleDeleteClick(params.row)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            loading={loading}
            autoHeight
            disableRowSelectionOnClick={true}
          />
        </Grid>
        {isMobile && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", mt: -1 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDriver}
              sx={{
                height: "56px",
                minWidth: "100px",
                padding: "0 16px",
              }}
            >
              + DRIVER
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Driver"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this driver?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DriversView;
