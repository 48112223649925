import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  List,
  ListItem,
  Divider,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const VehicleComplianceKB = () => {
  const [complianceData, setComplianceData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

  // Function to fetch the JSON data from Firestore
  const fetchComplianceData = async () => {
    try {
      // Reference to the specific document in the Firestore
      const docRef = doc(db, "fairway", "vehicleComplianceKnowledgeBase");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setComplianceData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setError("Error fetching data from Firestore.");
    }
  };

  useEffect(() => {
    fetchComplianceData(); // Fetch the data on component mount
  }, []);

  const handleFeedbackSubmit = async () => {
    setIsSubmitting(true);
    setSuccessMessage("");
    setError(null);

    const requestData = {
      state_name: selectedOption,
      transaction: selectedTransaction,
      feedback: feedback,
    };

    try {
      const response = await fetch(process.env.REACT_APP_GCF_COMPLIANCE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Success:", data);

      setSuccessMessage("Feedback submitted successfully!");
      setSnackbarOpen(true); // Show snackbar
      setFeedback("");
      setIsModalOpen(false);

      // Refresh section after feedback submission
      await fetchComplianceData();
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModal = () => {
    setModalTitle("Provide Feedback");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFeedback("");
  };

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setSelectedTransaction("");

    if (value !== "global_rules" && complianceData?.states[value]) {
      setTransactions(complianceData.states[value].transactions);
    } else {
      setTransactions([]);
    }
  };

  const handleTransactionChange = (event) => {
    setSelectedTransaction(event.target.value);
  };

  const renderDocumentsNeeded = (documents) => (
    <List>
      {documents.map((doc, index) => (
        <Box key={index} mb={1}>
          <Typography variant="subtitle2" gutterBottom>
            {doc.name}
          </Typography>
          <List>
            {doc.specifications.map((spec, idx) => (
              <ListItem key={idx} sx={{ display: "list-item", pl: 4 }}>
                {spec}
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </List>
  );

  const renderTransactionDetails = (transaction, hideName = false) => (
    <Box mb={4} p={2} component={Paper} elevation={3}>
      {!hideName && (
        <Typography variant="h6" gutterBottom>
          {transaction.name}
        </Typography>
      )}
      {transaction.documents_needed &&
        renderDocumentsNeeded(transaction.documents_needed)}
      {transaction.fee_calculation && (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Fee Calculation
          </Typography>
          <Typography paragraph>{transaction.fee_calculation}</Typography>
        </>
      )}
      {transaction.county_level_considerations && (
        <>
          <Typography variant="subtitle2" gutterBottom>
            County Level Considerations
          </Typography>
          <Typography paragraph>
            {transaction.county_level_considerations}
          </Typography>
        </>
      )}
    </Box>
  );

  const renderStateDetails = (stateData) => {
    const transactionDetails = selectedTransaction
      ? stateData.transactions.find(
          (transaction) => transaction.name === selectedTransaction,
        )
      : null;

    return (
      <>
        {transactionDetails
          ? renderTransactionDetails(transactionDetails)
          : stateData.transactions.map((transaction, index) => (
              <Box key={index} mb={3}>
                {renderTransactionDetails(transaction)}
                {index < stateData.transactions.length - 1 && (
                  <Divider sx={{ my: 2 }} />
                )}
              </Box>
            ))}
        {stateData.dmv_url && (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              DMV URL
            </Typography>
            <Typography paragraph>{stateData.dmv_url}</Typography>
          </Box>
        )}
      </>
    );
  };

  const renderGlobalRules = (globalRules) => (
    <>
      {Object.entries(globalRules).map(([ruleCategory, rules], index) => (
        <Box key={index} mb={3}>
          <Typography variant="h6" gutterBottom>
            {ruleCategory.replace(/_/g, " ")}
          </Typography>
          {typeof rules === "object" ? (
            <pre>{JSON.stringify(rules, null, 2)}</pre>
          ) : (
            <Typography paragraph>{rules}</Typography>
          )}
        </Box>
      ))}
    </>
  );

  return (
    <Box m="20px">
      {/* Snackbar for success message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      {error && <Typography color="error">Error: {error}</Typography>}

      {/* Dropdowns to select between Global Rules, States, and Transactions */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select State or Global Rules</InputLabel>
            <Select
              value={selectedOption}
              onChange={handleSelectionChange}
              label="Select State or Global Rules"
            >
              <MenuItem value="global_rules">Global Rules</MenuItem>
              {complianceData?.states &&
                Object.keys(complianceData.states)
                  .sort()
                  .map((stateName) => (
                    <MenuItem key={stateName} value={stateName}>
                      {stateName}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={!selectedOption || selectedOption === "global_rules"}
          >
            <InputLabel>Select Transaction</InputLabel>
            <Select
              value={selectedTransaction}
              onChange={handleTransactionChange}
              label="Select Transaction"
            >
              {transactions.map((transaction, index) => (
                <MenuItem key={index} value={transaction.name}>
                  {transaction.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Feedback Button - Only appears when both a state and a transaction are selected */}
      {selectedOption && selectedTransaction && (
        <Box mt={2} mb={4} display="flex" justifyContent="flex-start" gap={2}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "lightblue", color: "black" }}
            onClick={openModal}
          >
            Provide Feedback
          </Button>
        </Box>
      )}

      {/* Render JSON data based on dropdown selections */}
      {selectedOption === "global_rules" &&
        complianceData?.global_rules &&
        renderGlobalRules(complianceData.global_rules)}
      {selectedOption !== "global_rules" &&
        complianceData?.states[selectedOption] &&
        renderStateDetails(complianceData.states[selectedOption])}

      {/* Modal for Feedback */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          p={4}
          bgcolor="black"
          style={{
            margin: "auto",
            maxWidth: "500px",
            outline: "none",
            color: "white",
          }}
        >
          <Typography variant="h6">
            {modalTitle} for {selectedOption}{" "}
            {selectedTransaction ? `- ${selectedTransaction}` : ""}
          </Typography>

          {isSubmitting && (
            <Typography>
              Submitting feedback... <CircularProgress size={24} />
            </Typography>
          )}

          {!isSubmitting && (
            <>
              <TextField
                label="Feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                InputProps={{
                  style: { color: "white", backgroundColor: "#333" },
                }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleFeedbackSubmit}
                sx={{ marginRight: 2 }}
              >
                Submit
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default VehicleComplianceKB;
