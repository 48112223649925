import { useState, useEffect } from "react";
import { Grid, Box, Typography, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebaseConfig";
import { doc, collection, getDocs, setDoc } from "firebase/firestore";

const columns = [{ field: "email", headerName: "Email", width: 300 }];

const FairwayAdmins = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch users
        const usersCollection = collection(
          db,
          "fairway",
          "permissions",
          "superadmins",
        );
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          email: doc.id,
        }));
        setRows(usersList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleInvite = async () => {
    try {
      // Convert emails to lowercase
      const lowercasedNewEmail = newEmail.toLowerCase();
      // Create a new document with the email as the document ID in the "invitedUsers" collection
      await setDoc(
        doc(db, "fairway", "permissions", "superadmins", lowercasedNewEmail),
        {},
      );
      const newRow = {
        id: lowercasedNewEmail,
        email: lowercasedNewEmail,
      };
      setRows((prevRows) => [...prevRows, newRow]);
      setNewEmail("");
    } catch (error) {
      console.error("Error inviting user: ", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rows.length}
            pagination={false}
            loading={loading}
            autoHeight
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Super admins
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <TextField
              label="Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              fullWidth
            />
            <Button onClick={handleInvite} variant="contained" sx={{ ml: 2 }}>
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FairwayAdmins;
