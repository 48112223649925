import { orderBy, query } from "firebase/firestore";
import {
  addDocument,
  setCollection,
  subscribeToCollection,
} from "./databaseUtils";

export const addAuditorRunFile = async ({
  tool_name,
  customer_id,
  name,
  status,
  run_datetime,
  user_name,
  user_id,
  files,
}) => {
  const newRunFile = {
    name,
    customer_id,
    status,
    run_datetime,
    user_name,
    user_id,
    files,
  };

  const collectionPath = ["customers", customer_id, "tools", tool_name, "runs"];

  const result = await addDocument(collectionPath, newRunFile);
  return result ? { id: result.id } : null;
};

export const queryRuns = (companyId) =>
  query(
    setCollection(["customers", companyId, "tools", "AvaAuditor", "runs"]),
    orderBy("run_datetime", "desc"),
  );

export const subscribeToAuditorRuns = (companyId, onUpdate, onError) =>
  subscribeToCollection(queryRuns(companyId), onUpdate, onError);
