import { createContext, useContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// Context for accessing theme and toggling color mode
export const ColorModeContext = createContext();

export const useColorMode = () => useContext(ColorModeContext);

export const useMode = () => {
  const [mode, setMode] = useState("dark"); // Default mode

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          // Only add/modify specific entries
          background: {
            default: mode === "dark" ? "#121212" : "rgb(250, 250, 251)", // Main background color
            secondary: mode === "dark" ? "#1e1e1e" : "#ffffff", // Secondary background color
          },
          // Here you can add other custom colors
          myCustomColor: mode === "dark" ? "#333333" : "#cccccc", // Example custom color
          // Existing colors like 'primary', 'secondary' can also be customized here if needed
        },
        typography: {
          fontFamily: "Poppins, sans-serif",
          // Additional typography settings can go here
        },
      }),
    [mode],
  );

  const colorMode = useMemo(
    () => ({
      theme, // Pass the entire theme object
      toggleColorMode: () =>
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light")),
    }),
    [theme],
  );

  return colorMode;
};
