import React, { useState } from "react";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { storage, db } from "../../firebaseConfig";
import { useUser } from "../../contexts/UserContext"; // Assuming you've initialized Firestore as `db`
import { v4 as uuidv4 } from "uuid";

const FileUpload = ({ multiple, onSuccess }) => {
  const { user } = useUser();
  const [uploading, setUploading] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files.length) {
      setError("No file selected");
      return;
    }

    setUploading(true);
    setError(""); // Clear any existing errors

    // Generate a unique ID and modify the file name
    for (const file of files) {
      const uniqueId = uuidv4();
      const fileName = `${uniqueId}-${file.name}`;

      const storagePath = `uploads/${user.companyId}/${user.uid}/${fileName}`;
      const storageRef = ref(storage, storagePath);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(snapshot.ref);
        const gsUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`; // Construct gs:// URL
        console.log(gsUrl);
        const collectionRef = collection(
          db,
          `customers/${user.companyId}/users/${user.uid}/files`,
        );
        const fileData = {
          name: file.name,
          size: file.size,
          type: file.type,
          downloadURL: url,
          gsURL: gsUrl, // Include gs:// URL
          uploadedAt: serverTimestamp(),
        };
        const docRef = await addDoc(collectionRef, fileData);

        setDownloadURL(url); // Update state with the download URL
        onSuccess({ url, gsUrl, filename: file.name, docId: docRef.id });
      } catch (uploadError) {
        setUploading(false);
        console.error("Error uploading file: ", uploadError);
        setError("Error uploading file. Please try again."); // Update state with the error message
      }
    }

    setUploading(false);
    setSuccess(true);
    setTimeout(() => setSuccess(false), 2000); // Hide success icon after 2 seconds
  };

  return (
    <Box display="flex" alignItems="center" ml={1}>
      <input
        type="file"
        onChange={handleFileChange}
        disabled={uploading}
        style={{ display: "none", mr: 1 }}
        id="file-upload"
        multiple={multiple}
      />
      <label htmlFor="file-upload" sx={{ mr: 1 }}>
        <IconButton color="primary" component="span" disabled={uploading}>
          {uploading ? <CircularProgress size={24} /> : <UploadFileIcon />}
        </IconButton>
      </label>
      {success && (
        <Tooltip title="File uploaded successfully" sx={{ mr: 1 }}>
          <CheckCircleIcon color="success" />
        </Tooltip>
      )}
      {error && (
        <Tooltip title={error} sx={{ mr: 1 }}>
          <ErrorIcon color="error" />
        </Tooltip>
      )}
    </Box>
  );
};

export default FileUpload;
