import { db, auth } from "../firebaseConfig";
import {
  doc,
  collection,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const fetchInviteData = async (
  location,
  setInvitation,
  setLoadingUserInviteData,
) => {
  const pathname = location.pathname.toLowerCase();
  const match = pathname.match(/companyinvite\/([^/]+)/);
  const inviteIdFromUrl = match ? match[1] : null;

  if (inviteIdFromUrl) {
    // Fetch invite data from Firestore using the inviteIdFromUrl
    const inviteDocRef = doc(
      db,
      "fairway",
      "onboarding",
      "companyInvitation",
      inviteIdFromUrl,
    );
    const inviteDocSnapshot = await getDoc(inviteDocRef);
    if (inviteDocSnapshot.exists()) {
      const inviteData = inviteDocSnapshot.data();
      setInvitation({
        id: inviteIdFromUrl,
        type: "companyInvite",
        userEmail: inviteData.userEmail,
        companyId: inviteData.companyId,
        companyName: inviteData.companyName,
        pathname: location.pathname,
      });
    } else {
      console.log("No such document!");
    }
  } else if (pathname === "/companyonboarding") {
    console.log("match companyOnboarding");
    setInvitation({
      type: "companyOnboarding",
    });
  }
  setLoadingUserInviteData(false);
};

const createCompany = async (userData, companyName) => {
  const companyData = {
    name: companyName,
    field: "",
    paperworkState: null,
    creator: userData.uid,
    onboardingStatus: {
      delayImpactFields: false,
      requiredFields: false,
      status: false,
      vehicles: false,
      paperworkState: null,
    },
    createdAt: new Date(),
  };
  try {
    const customersCollection = collection(db, "customers");
    console.log("Creating company");
    const docRef = await addDoc(customersCollection, companyData);
    const companyId = docRef.id;
    console.log("Company created: ", companyId);
    const userRef = doc(db, "users", userData.uid);

    // Update the user document with the companyId
    console.log("Updating company id in user");
    await updateDoc(userRef, {
      companyId: companyId,
    });
    console.log("Updating user data with companyId");

    // Create user in company
    console.log("Create user in company");
    await setDoc(doc(db, "customers", companyId, "users", userData.uid), {
      name: userData.name,
      email: userData.email,
      role: "admin",
    });

    // Give user admin access
    console.log("Give user admin access");
    await setDoc(
      doc(
        db,
        "customers",
        companyId,
        "settings",
        "permissions",
        "adminUsers",
        userData.email,
      ),
      { name: userData.name, email: userData.email, role: "admin" },
    );

    // Initial Paperwork Data
    await setDoc(doc(db, "customers", companyId, "settings", "paperworkData"), {
      name: companyName,
      companyName: null,
      federalIDNumber: null,
      businessLicense: null,
      IRSgoodStandingDocumentation: null,
      contactName: null,
      contactPhone: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zipcode: null,
    });

    // Eliminate newCompany permission
    await deleteDoc(
      doc(db, "fairway", "onboarding", "newCompany", userData.email),
    );

    return companyId;
  } catch (error) {
    console.error("Error adding document: ", error);
    return null;
  }
};

const getUserCompanyRole = async (companyId, uid) => {
  const companyUserRef = doc(db, "customers", companyId, "users", uid);
  const companyUserSnap = await getDoc(companyUserRef);
  if (companyUserSnap.exists()) {
    return companyUserSnap.data().role;
  } else {
    return null;
  }
};

const getNewCompanyInvite = async (userEmail) => {
  const inviteRef = doc(db, "fairway", "onboarding", "newCompany", userEmail);
  const inviteSnap = await getDoc(inviteRef);
  if (inviteSnap.exists()) {
    return inviteSnap.data();
  } else {
    return false;
  }
};

const createUser = async (authUserData) => {
  const userRef = doc(db, "users", authUserData.uid);
  const newUserData = {
    name: authUserData.displayName,
    email: authUserData.email,
    createdAt: new Date(),
    companyId: null,
    uid: authUserData.uid,
  };
  await setDoc(userRef, newUserData);
  return newUserData;
};

const linkUserToCompany = async (userData, invitation, role) => {
  const companyId = invitation.companyId;
  const userRef = doc(db, "users", userData.uid);
  const companyUserRef = doc(db, "customers", companyId, "users", userData.uid);
  await updateDoc(userRef, {
    companyId: companyId,
    role: role,
    demoCompany: companyId === "demo",
  });
  userData.companyId = companyId;
  userData.role = role;
  await setDoc(companyUserRef, userData);
  await deleteDoc(
    doc(
      db,
      "customers",
      companyId,
      "settings",
      "permissions",
      "invitedUsers",
      userData.email,
    ),
  );
  await deleteDoc(
    doc(db, "fairway", "onboarding", "companyInvitation", invitation.id),
  );
  return true;
};

const isSuperAdmin = async (userEmail) => {
  try {
    const inviteRef = doc(
      db,
      "fairway",
      "permissions",
      "superadmins",
      userEmail,
    );
    const inviteSnap = await getDoc(inviteRef);
    if (inviteSnap.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export {
  fetchInviteData,
  createCompany,
  getUserCompanyRole,
  getNewCompanyInvite,
  createUser,
  linkUserToCompany,
  isSuperAdmin,
};
