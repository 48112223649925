import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  useMemo,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import {
  fetchInviteData,
  createUser,
  getNewCompanyInvite,
  createCompany,
  linkUserToCompany,
  getUserCompanyRole,
  isSuperAdmin,
} from "./helpers";

const defaultUserContext = {
  user: null,
  company: null,
};

const UserContext = createContext(defaultUserContext);

export const useUser = () => useContext(UserContext);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const setToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getFromStorage = (key) => {
  const value = localStorage.getItem(key);
  try {
    return value ? JSON.parse(value) : null;
  } catch (error) {
    return null;
  }
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getFromStorage("user"));
  const [userError, setUserError] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [company, setCompany] = useState(getFromStorage("company"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [authUserData, setAuthUserData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingUserContext, setLoadingUserContext] = useState(true);
  const [loadingUserInviteData, setLoadingUserInviteData] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const [backendUrl, setBackendUrl] = useState("");

  // BackendUrl
  useEffect(() => {
    let url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
    if (url.includes(":3000")) {
      url = url.replace(":3000", ":5000");
      url = url.replace("localhost", "127.0.0.1");
    } else {
      // Dev environment: change URL to point to production backend
      url = process.env.REACT_APP_BACKEND_URL;
    }
    setBackendUrl(url);
  }, [location]);

  // Invitation state from path
  useEffect(() => {
    fetchInviteData(location, setInvitation, setLoadingUserInviteData);
  }, []);

  // Invitation state from path

  const loadUserToContext = async () => {
    setLoadingUser(true);
    setLoadingUserContext(true);
    setIsLoggedIn(true);

    // Fetch user data
    const userRef = doc(db, "users", authUserData.uid);
    const docSnap = await getDoc(userRef);
    const userExists = docSnap.exists();
    let userData;
    let companyId;

    if (userExists) {
      userData = docSnap.data();
    } else {
      userData = await createUser(authUserData);
    }

    if (invitation) {
      if (invitation?.type === "companyOnboarding") {
        const newCompanyInvite = await getNewCompanyInvite(userData.email);
        if (newCompanyInvite) {
          const companyName = newCompanyInvite.companyName;
          companyId = await createCompany(userData, companyName);
          userData.companyId = companyId;
          userData.role = "admin";
        } else {
          setUserError(true);
        }
      } else if (invitation?.type === "companyInvite") {
        await linkUserToCompany(userData, invitation, "user");
      } else if (invitation?.type === "signInLinkSent") {
        // Do nothing
      } else {
        setUserError(true);
      }
      // No invitation data
    } else {
      if (userData.companyId) {
        companyId = userData.companyId;
        // Assign and get role in context
        userData.role = await getUserCompanyRole(companyId, userData.uid);
      } else {
        // User has no invitation and no assigned company
        setUserError(true);
      }
    }

    if (!userData?.role && userData?.companyId) {
      userData.role = await getUserCompanyRole(
        userData.companyId,
        userData.uid,
      );
    }
    userData.superAdmin = await isSuperAdmin(userData.email);
    console.log("user: ", userData);
    setUser(userData);
    setToStorage("user", userData);
    setLoadingUser(false);
    setLoadingUserContext(false);
  };

  // Auhentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (authStateChangeData) => {
        setLoadingUserContext(true);
        if (authStateChangeData) {
          setAuthUserData(authStateChangeData);
        } else {
          setUserError(false);
          setUser(null);
          setCompany(null);
          setIsLoggedIn(false);
          localStorage.removeItem("user");
          localStorage.removeItem("company");
        }
        setLoadingUserContext(false);
      },
    );
    return () => unsubscribe();
  }, []);

  // User state load
  useEffect(() => {
    if (authUserData && invitation?.type !== "signInLinkSent") {
      loadUserToContext();
    }
  }, [authUserData, invitation]);

  // Fetch company data if user has a companyId
  useEffect(() => {
    const fetchCompanyData = async function () {
      if (user?.companyId) {
        const companyRef = doc(db, "customers", user.companyId);
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          const companyData = { ...companySnap.data(), id: user.companyId };
          setCompany(companyData);
          setToStorage("company", companyData);
        }
      }
    };
    if (!loadingUser) {
      fetchCompanyData();
    }
  }, [user?.companyId, loadingUser]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // const value = { user, setUser, company, onboardingSession, setOnboardingSession };
  const value = useMemo(
    () => ({
      backendUrl,
      user,
      setUser,
      company,
      setCompany,
      userError,
      invitation,
      setInvitation,
      loadingUserContext,
      loadingUserInviteData,
      isLoggedIn,
    }),
    [
      backendUrl,
      user,
      setUser,
      company,
      setCompany,
      userError,
      invitation,
      setInvitation,
      loadingUserContext,
      loadingUserInviteData,
      isLoggedIn,
    ],
  );

  return (
    <UserContext.Provider value={value}>
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ top: { xs: "20px", md: "20px" } }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Hello {user?.name || "there"}!
        </Alert>
      </Snackbar>
    </UserContext.Provider>
  );
};
