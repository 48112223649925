import { useState, useEffect } from "react";
import { useColorMode } from "../../theme";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useUser } from "../../contexts/UserContext";
import { db } from "../../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";

const UserProfile = () => {
  const { theme } = useColorMode();
  const { user } = useUser();
  const [name, setName] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setName(user.name);
  }, [user?.name]);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setIsModified(true);
  };

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, { name });
      setIsModified(false);
    } catch (error) {
      console.error("Error saving user data: ", error);
    }
    setSaveLoading(false);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flex: 1,
        }}
      >
        <Box sx={{ m: 1, width: "300px" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <OutlinedInput
              id="name"
              value={name}
              onChange={handleNameChange}
              label="Name"
            />
          </FormControl>
        </Box>
        <Box sx={{ m: 1, width: "300px" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <OutlinedInput
              id="email"
              value={user.email}
              label="Email"
              disabled
            />
          </FormControl>
        </Box>
        <Box sx={{ m: 1, width: "300px" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="role">Role</InputLabel>
            <OutlinedInput id="role" value={user.role} label="Role" disabled />
          </FormControl>
        </Box>
        {isModified && (
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={saveLoading}
              sx={{ height: 56, width: 120 }} // Ensures the button has the same height as the inputs
            >
              {saveLoading ? "Saving..." : "Save"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserProfile;
