import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { checkDocumentExists, setDocument } from "./databaseUtils";

export const existingVehicle = async (companyId, vehicleVin) =>
  getDoc(doc(db, "customers", companyId, "decodeVIN", vehicleVin));

export const fetchVehicleById = async (companyId, vehicleId) => {
  try {
    const collectionPath = ["customers", companyId, "vehicles"];
    const vehicleDoc = await checkDocumentExists(collectionPath, vehicleId);
    if (vehicleDoc) {
      return vehicleDoc.data(); // Return the data if the document exists
    } else {
      console.error("No such vehicle document!");
      return null; // Return null if the document does not exist
    }
  } catch (error) {
    console.error("Error fetching vehicle: ", error);
    throw error; // Rethrow the error to be handled by the calling function
  }
};

export const saveVehicleDocument = async (
  companyId,
  vehicleId,
  vehicleData,
) => {
  try {
    const collectionPath = ["customers", companyId, "vehicles"];
    const isSaved = await setDocument(collectionPath, vehicleId, vehicleData);

    if (!isSaved) {
      console.error("Failed to save the vehicle document.");
    }
    return true; // Indicate success
  } catch (error) {
    console.error("Error saving vehicle document:", error);
    throw error; // Rethrow the error to be handled by the calling function
  }
};
