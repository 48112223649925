import React from "react";
import { TextField, IconButton, Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const ContactTable = ({ formData, setFormData }) => {
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    // Check if the phone_number starts with a '+'; if not, add '+1'
    if (name === "phone_number" && !value.startsWith("+")) {
      updatedValue = `+1${value}`;
    }

    const updatedRows = [...formData.contacts_of_interest];
    updatedRows[index] = { ...updatedRows[index], [name]: updatedValue };

    setFormData((prevData) => ({
      ...prevData,
      contacts_of_interest: updatedRows,
    }));
  };

  const handleAddRow = () => {
    const updatedRows = [
      ...formData.contacts_of_interest,
      { name: "", phone_number: "", role: "" },
    ];

    setFormData((prevData) => ({
      ...prevData,
      contacts_of_interest: updatedRows,
    }));
  };

  const handleRemoveRow = (index) => {
    const updatedRows = formData.contacts_of_interest.filter(
      (_, i) => i !== index,
    );

    setFormData((prevData) => ({
      ...prevData,
      contacts_of_interest: updatedRows,
    }));
  };

  return (
    <Box sx={{ bgcolor: "background.paper", p: 2, mb: 2, borderRadius: 1 }}>
      <Typography variant="subtitle1" gutterBottom>
        Contacts of interest
      </Typography>
      {formData.contacts_of_interest.map((row, index) => (
        <Box
          key={index}
          sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}
        >
          <TextField
            label="Phone Number"
            name="phone_number"
            value={row.phone_number}
            onChange={(e) => handleInputChange(index, e)}
            sx={{ mr: 2 }}
            fullWidth
            required
          />
          <TextField
            label="Name"
            name="name"
            value={row.name}
            onChange={(e) => handleInputChange(index, e)}
            sx={{ mr: 2 }}
            fullWidth
            required
          />
          <TextField
            label="Role"
            name="role"
            value={row.role}
            onChange={(e) => handleInputChange(index, e)}
            sx={{ mr: 2 }}
            fullWidth
          />
          <IconButton
            onClick={() => handleRemoveRow(index)}
            disabled={formData.contacts_of_interest.length === 1}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddRow}>
        Add Contact
      </Button>
    </Box>
  );
};

export default ContactTable;
