import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { ProSidebar } from "react-pro-sidebar";
import Drawer from "@mui/material/Drawer";
import "react-pro-sidebar/dist/css/styles.css";
import { useColorMode } from "../../theme";
import SidebarContent from "./SidebarContent";

const Sidebar = ({ isCollapsed, isDrawerOpen, setIsDrawerOpen }) => {
  const { theme } = useColorMode();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState("Compliance");
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/settings") {
      setSelected(null);
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& .pro-sidebar-inner": {
          background: `${theme.palette.background.secondary}`,
          borderRight:
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      {!isMobile && (
        <ProSidebar collapsed={isCollapsed}>
          <SidebarContent
            isCollapsed={isCollapsed}
            selected={selected}
            setSelected={setSelected}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </ProSidebar>
      )}

      {isMobile && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box
            sx={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ProSidebar collapsed={isCollapsed}>
              <SidebarContent
                isCollapsed={isCollapsed}
                selected={selected}
                setSelected={setSelected}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </ProSidebar>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
