import { useState, useEffect } from "react";
import { useColorMode } from "../../theme";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebaseConfig";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";
import {
  ContentCopy as CopyIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const columns = [
  { field: "name", headerName: "Name", width: 130 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "role", headerName: "Role", width: 130 },
];

const ManageUsers = () => {
  const { theme } = useColorMode();
  const { user, company } = useUser();
  const [rows, setRows] = useState([]);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  const location = useLocation();
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
    setBaseUrl(url);
  }, [location]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!company) return;
      try {
        // Fetch users
        const usersCollection = collection(
          db,
          "customers",
          company.id,
          "users",
        );
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRows(usersList);

        const invitedUsersRef = collection(
          db,
          "customers",
          company.id,
          "settings",
          "permissions",
          "invitedUsers",
        );
        const invitedUsersExistsSnapshot = await getDocs(invitedUsersRef);
        if (!invitedUsersExistsSnapshot.empty) {
          // The "invitedUsers" collection exists, proceed to fetch its documents
          const invitedUsersSnapshot = await getDocs(invitedUsersRef);
          const invitedUsersList = invitedUsersSnapshot.docs.map((doc) => ({
            inviteEmail: doc.id,
            inviteId: doc.data().inviteId, // Access the inviteId stored in the document data
          }));
          setInvitedEmails(invitedUsersList);
        } else {
          // The "invitedUsers" collection does not exist yet
          // You can handle this situation according to your application's logic,
          // for example, by setting an empty array for invited emails
          setInvitedEmails([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [company]);

  const isEmailInvited = invitedEmails.some(
    (invite) => invite.inviteEmail === newEmail,
  );

  const handleInvite = async () => {
    try {
      // Convert emails to lowercase
      const lowercasedNewEmail = newEmail.toLowerCase();
      const lowercasedUserEmail = user.email.toLowerCase();

      // Create a new document with a generated ID in the "userInvites" collection
      const inviteRef = await addDoc(
        collection(db, "fairway", "onboarding", "companyInvitation"),
        {
          companyId: company.id,
          companyName: company.name,
          userEmail: lowercasedNewEmail,
          invitedBy: lowercasedUserEmail,
        },
      );

      // Get the generated ID of the newly created document
      const inviteId = inviteRef.id;

      // Create a new document with the email as the document ID in the "invitedUsers" collection
      await setDoc(
        doc(
          db,
          "customers",
          company.id,
          "settings",
          "permissions",
          "invitedUsers",
          lowercasedNewEmail,
        ),
        {
          inviteId: inviteId,
        },
      );

      // Update the state with the new invited email
      const updatedInvitedEmails = [
        ...invitedEmails,
        {
          inviteId: inviteId,
          inviteEmail: lowercasedNewEmail,
        },
      ];
      setInvitedEmails(updatedInvitedEmails);
      setNewEmail("");
    } catch (error) {
      console.error("Error inviting user: ", error);
    }
  };

  const handleInviteDelete = async (inviteEmail, inviteId) => {
    try {
      // Delete the document with the given email as the document ID from the "invitedUsers" collection
      await deleteDoc(
        doc(
          db,
          "customers",
          company.id,
          "settings",
          "permissions",
          "invitedUsers",
          inviteEmail,
        ),
      );

      // Delete the invite
      await deleteDoc(
        doc(db, "fairway", "onboarding", "companyInvitation", inviteId),
      );

      // Update the state to remove the deleted email
      const updatedInvitedEmails = invitedEmails.filter(
        (invite) => invite.inviteEmail !== inviteEmail,
      );
      setInvitedEmails(updatedInvitedEmails);
    } catch (error) {
      console.error("Error deleting invited user: ", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rows.length}
            pagination={false}
            loading={loading}
            checkboxSelection
            autoHeight
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Invited Emails
          </Typography>
          {invitedEmails.length > 0 ? (
            invitedEmails.map((invite, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">{invite.inviteEmail}</Typography>
                <Box>
                  <IconButton
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${baseUrl}/companyInvite/${invite.inviteId}`,
                      )
                    }
                    aria-label="copy"
                  >
                    <CopyIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleInviteDelete(invite.inviteEmail, invite.inviteId)
                    }
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1">No invited emails</Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <TextField
              label="Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              fullWidth
            />
            <Tooltip
              title={
                isEmailInvited ? "This email has an invitation pending" : ""
              }
              placement="bottom"
            >
              <span>
                <Button
                  onClick={handleInvite}
                  variant="contained"
                  sx={{ ml: 2 }}
                  disabled={isEmailInvited}
                >
                  Invite
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageUsers;
