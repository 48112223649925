import { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useColorMode } from "../../theme";
import { Box, Typography, Breadcrumbs, Link, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import UserProfile from "./userProfile";
import CompanyInformation from "./companyInformation";
import ManageUsers from "./manageUsers";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";

const Settings = () => {
  const { theme } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const isAdmin = user && user.role === "admin";
  const [value, setValue] = useState(
    location.pathname.split("/")[2] || "profile",
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/settings/${newValue}`);
  };

  useEffect(() => {
    setValue(location.pathname.split("/")[2] || "profile");
  }, [location]);

  return (
    <Box m="20px">
      <Breadcrumbs maxItems={2} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/settings">
          Settings
        </Link>
        <Link underline="hover" color="inherit" href={`/settings/${value}`}>
          {value === "profile"
            ? "User Profile"
            : value === "company"
              ? "Company Information"
              : "Manage Users"}
        </Link>
      </Breadcrumbs>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Settings" />
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="Settings Tabs">
              <Tab label="User Profile" value="profile" />
              {isAdmin && <Tab label="Company Information" value="company" />}
              {isAdmin && <Tab label="Manage Users" value="users" />}
            </TabList>
          </Box>
          <TabPanel value="profile">
            <UserProfile />
          </TabPanel>
          {isAdmin && (
            <>
              <TabPanel value="company">
                <CompanyInformation />
              </TabPanel>
              <TabPanel value="users">
                <ManageUsers />
              </TabPanel>
            </>
          )}
        </TabContext>
      </Box>
    </Box>
  );
};

export default Settings;
