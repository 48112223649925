export const actionList = [
  "insuranceDocumentation",
  "emissionTest",
  "scheduleInspection",
  "startRegistrationRenewal",
  "weightTest",
];

export const statusList = [
  { text: "Compliant", dotColor: "#00FF00" },
  { text: "Expiring Soon", dotColor: "#FFFF00" },
  { text: "Non Compliant", dotColor: "#FF0000" },
  { text: "Missing Documentation", dotColor: "#FFFF00" },
];

export const criticalItemList = [
  "Insurance",
  "Emission Test",
  "Inspection",
  "Registration",
  "Weight Test",
];

export const vehicleSchema = {
  vin: null,
  licensePlate: null,
  carImage: null,
  carName: null,
  recommendedTasks: [],
  options: ["View", "Archive"],
  groupID: null,
  groupName: null,
  additional_user_input: {
    "Vehicle Proof of Insurance (PDF/Image)": null,
  },
};

export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Add leading zero if needed
  const day = `0${date.getDate()}`.slice(-2); // Add leading zero if needed
  return `${year}-${month}-${day}`;
};

export const generateNewVehicle = (optionalNextRenewalDate = null) => {
  const newVehicle = { ...vehicleSchema };

  const tasks = {
    startRegistrationRenewal: {
      nextRenewalDate: optionalNextRenewalDate,
      expiringSoonDate: optionalNextRenewalDate
        ? new Date(
            new Date(optionalNextRenewalDate).setMonth(
              new Date(optionalNextRenewalDate).getMonth() - 3,
            ),
          )
            .toISOString()
            .split("T")[0]
        : null,
      status: "",
    },
    scheduleInspection: {
      nextRenewalDate: "",
      expiringSoonDate: "",
      status: "assumeOK",
    },
    emissionTest: {
      nextRenewalDate: "",
      expiringSoonDate: "",
      status: "notApplicable",
    },
    weightTest: {
      nextRenewalDate: "",
      expiringSoonDate: "",
      status: "notApplicable",
    },
    insuranceDocumentation: {
      nextRenewalDate: "",
      expiringSoonDate: "",
      status: "assumeOK",
    },
  };

  newVehicle.recommendedTasks = tasks;

  return newVehicle;
};

export const calculateVehicleInfo = (vehicle) => {
  const currentDate = new Date();

  let status = "Compliant";
  let criticalItem = "";
  let nextDate = null;
  let recommendedActions = ["startRegistrationRenewal", "scheduleInspection"];
  let recommendStatus = ["running", "ok"];

  let tasksWithDates = [];

  for (const taskName in vehicle.recommendedTasks) {
    const task = vehicle.recommendedTasks[taskName];
    if (task.nextRenewalDate && task.status !== "notApplicable") {
      const taskNextDate = new Date(task.nextRenewalDate);
      tasksWithDates.push({ taskName, taskNextDate, status: task.status });
    }
  }

  const startRegistration = tasksWithDates.find(
    (task) => task.taskName === "startRegistrationRenewal",
  );

  if (!startRegistration || !startRegistration.taskNextDate) {
    status = "Missing Documentation";
  } else {
    tasksWithDates.forEach((task) => {
      if (
        task.taskName !== "startRegistrationRenewal" &&
        task.taskName !== "scheduleInspection"
      ) {
        recommendedActions.push(task.taskName);
      }
    });

    tasksWithDates.sort((a, b) => a.taskNextDate - b.taskNextDate);
    nextDate =
      tasksWithDates.length > 0 ? tasksWithDates[0].taskNextDate : null;
    criticalItem =
      tasksWithDates.length > 0
        ? criticalItemList[actionList.indexOf(tasksWithDates[0].taskName)]
        : "";

    if (!nextDate || (nextDate && nextDate < currentDate)) {
      status = "Non Compliant";
    } else if (
      nextDate &&
      nextDate < new Date(currentDate.setMonth(currentDate.getMonth() + 3))
    ) {
      status = "Expiring Soon";
    } else {
      status = "Compliant";
    }
  }

  return {
    status: {
      text: status,
      dotColor: statusList.find((s) => s.text === status).dotColor,
    },
    nextDate: nextDate ? nextDate.toISOString().split("T")[0] : null,
    criticalItem: criticalItem,
    recommendedActions: recommendedActions,
  };
};
