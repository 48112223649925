import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import Login from "@mui/icons-material/Login";
import { useUser } from "../../contexts/UserContext";
import { getAuth, signOut } from "firebase/auth";
import { useColorMode } from "../../theme"; // Ensure correct path

const AccountMenu = ({ anchorEl, open, onClose, setSignInOpen }) => {
  const { theme } = useColorMode();
  const { user } = useUser(); // Determines if a user is logged in

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      className="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded" // change class to className
      sx={{
        "& .MuiPaper-root": {
          overflow: "visible",
          mt: 1.5,
          opacity: 1,
          filter: "none",
          backgroundColor: `${theme.palette.background.secondary} !important`,
          "& .MuiList-root": {
            opacity: 1,
            filter: "none",
            backgroundColor: `${theme.palette.background.secondary} !important`,
          },
          "& .MuiAvatar-root": {
            opacity: 1,
            filter: "none",
            backgroundColor: `${theme.palette.background.primary} !important`,
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            backgroundColor: `${theme.palette.background.primary} !important`,
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {user ? (
        // If user is logged in, display Logout option
        <MenuItem
          onClick={() => {
            const auth = getAuth();
            signOut(auth)
              .then(() => {
                // Sign-out successful.
                onClose(); // Close the menu
              })
              .catch((error) => {
                // An error happened.
                console.error("Sign out error:", error);
              });
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      ) : (
        // If user is not logged in, display My account and Sign in options
        <>
          <MenuItem onClick={onClose}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setSignInOpen(true);
              onClose();
            }}
          >
            <ListItemIcon>
              <Login fontSize="small" />
            </ListItemIcon>
            Sign in
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default AccountMenu;
