import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

// Generalized function to add a document
export const addDocument = async (collectionPath, data) => {
  try {
    return await addDoc(collection(db, ...collectionPath), data);
  } catch (error) {
    console.error("Error adding document: ", error);
    return null;
  }
};

export const deleteDocument = async (docPath) => {
  try {
    await deleteDoc(doc(db, ...docPath));
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

export const setDocument = async (
  collectionPath,
  docId,
  data,
  options = {},
) => {
  try {
    const documentRef = doc(db, ...collectionPath, docId);
    await setDoc(documentRef, data, options);
    return true; // Return true if successful
  } catch (error) {
    console.error("Error setting document:", error);
    return false; // Return false if there was an error
  }
};

export const setCollection = (collectionPath) => {
  try {
    return collection(db, ...collectionPath);
  } catch (error) {
    console.error("Error setting collection:", error);
    return null;
  }
};

export const subscribeToCollection = (query, onUpdate, onError) => {
  try {
    return onSnapshot(query, (snapshot) => {
      const dataList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      onUpdate(dataList); // Call the callback function with the updated data
    }); // Return the unsubscribe function to stop listening when needed
  } catch (error) {
    console.error("Error subscribing to collection:", error);
    if (onError) onError(error); // Call the error callback if provided
    return null;
  }
};

export const subscribeToDocument = (docRef, onUpdate, onError) => {
  try {
    return onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          onUpdate(doc);
        } else {
          onError(new Error("No such document!"));
        }
      },
      (error) => {
        console.error("Error subscribing to document:", error);
        if (onError) onError(error);
      },
    );
  } catch (error) {
    console.error("Error setting up document subscription:", error);
    if (onError) onError(error);
    return null;
  }
};

export const fetchCollectionDocuments = async (collectionPath) => {
  try {
    const collectionRef = collection(db, ...collectionPath);
    const snapshot = await getDocs(collectionRef);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching documents:", error);
    return [];
  }
};

export const uploadFile = async ({
  companyId,
  toolName,
  gsUrl,
  url,
  filename,
  docId,
  uploadedBy,
  uploadedAt,
}) => {
  const newFile = { gsUrl, url, filename, docId, uploadedBy, uploadedAt };

  const collectionPath = ["customers", companyId, "tools", toolName, "files"];

  const result = await addDocument(collectionPath, newFile);
  return result ? newFile : null;
};

export const updateDocument = async (collectionPath, docId, data) => {
  try {
    const docRef = doc(db, ...collectionPath, docId);
    await updateDoc(docRef, data);
    return true;
  } catch (error) {
    console.error("Error updating document:", error);
    return false; // Return false if there was an error
  }
};

export const checkDocumentExists = async (collectionPath, docId) => {
  try {
    const docSnap = await getDoc(doc(db, ...collectionPath, docId));
    return docSnap.exists() ? docSnap : null;
  } catch (error) {
    console.error("Error checking document existence:", error);
    return null;
  }
};
