import { tokens } from "../theme";

export const mockOpportunities = [
  {
    bold: "One supplier",
    desc: "has not been negotiated with in the last 12 months.",
  },
  {
    bold: "Two suppliers",
    desc: "are without clear owners.",
  },
  {
    bold: "Three suppliers",
    desc: "have seen price increases in the last 12 months.",
  },
  {
    bold: "Four suppliers",
    desc: "are underutilized, generating waste.",
  },
  {
    bold: "Five suppliers",
    desc: "could have tasks deleted or taken in-house.",
  },
  {
    bold: "One supplier",
    desc: "offers terms of payment that are too short.",
  },
  {
    bold: "Two suppliers",
    desc: "have contract renewals coming up.",
  },
  {
    bold: "Three suppliers",
    desc: "do not include discounts for volume or length of the contract.",
  },
  {
    bold: "Four suppliers",
    desc: "have very low NPS, ranked by users/owners.",
  },
  {
    bold: "Five suppliers",
    desc: "charge penalty or additional fees that are not apparent in the contract.",
  },
  {
    bold: "One supplier",
    desc: "includes price premiums for high SLA that is not valued or used.",
  },
  {
    bold: "Two suppliers",
    desc: "have multiple contracts across different areas.",
  },
  {
    bold: "Three suppliers",
    desc: "supply goods that are underused or discarded and could be reprocessed.",
  },
  {
    bold: "Four suppliers",
    desc: "offer products or services that are over-specked for some of their current uses.",
  },
  {
    bold: "Five suppliers",
    desc: "could have their products or services bundled into fewer providers or products.",
  },
  {
    bold: "One supplier",
    desc: "is known to have low value (NPS).",
  },
  {
    bold: "Two suppliers",
    desc: "are known to have low productivity compared to peers.",
  },
  {
    bold: "Three suppliers",
    desc: "are facing negative media pressure.",
  },
  {
    bold: "Four suppliers",
    desc: "are known for offering products at prices higher than market benchmarks.",
  },
  {
    bold: "Five suppliers",
    desc: "offer products known to be obsolete or outdated.",
  },
];

export const mockActionItems = [
  {
    owner: "Me",
    actions: [
      {
        status: "open",
        description:
          "Review and approve automatic registration renewal for 3 vehicles.",
      },
      {
        status: "done",
        description: "Upload new valid insurance documentation for 1 vehicle.",
      },
    ],
  },
  {
    owner: "Bob",
    actions: [
      {
        status: "open",
        description:
          "Missing suppliers! There seems to be 10% of total cost missing from the reported amount.",
      },
    ],
  },
  {
    owner: "Alice",
    actions: [
      {
        status: "open",
        description: "Send intro email to recommended suppliers.",
      },
      {
        status: "open",
        description:
          "Cancel contracts related to the RIF in the accounting department.",
      },
      {
        status: "in progress",
        description: "Meet with user to reduce / anticipate volatility in use.",
      },
      {
        status: "done",
        description:
          "Contract with Maintenance Service firm Adelco expires soon.",
      },
      {
        status: "done",
        description: "Review recommendation - rapid renegotiation vs RFP.",
      },
    ],
  },
  {
    owner: "Bob",
    actions: [
      {
        status: "open",
        description: "Review Proposed decision criteria Matrix.",
      },
      {
        status: "open",
        description: "Email new request for bundled and volume discount.",
      },
      {
        status: "done",
        description:
          "Opportunity to bundle contract for general parts into 1 supplier.",
      },
    ],
  },
];

export const mockBarData = [
  { x: "23-04", y: -666941 },
  { x: "23-05", y: -594045 },
  { x: "23-06", y: -793539 },
  { x: "23-07", y: -550139 },
  { x: "23-08", y: -982967 },
  { x: "23-09", y: -848003 },
  { x: "23-10", y: -773765 },
  { x: "23-11", y: -860304 },
  { x: "23-12", y: -736430 },
  { x: "24-01", y: -587628 },
  { x: "24-02", y: -910178 },
  { x: "24-03", y: -817797 },
];

export const mockHorizontalBarChartData = [
  { supplier: "ZenithCorp", opportunities: 7 },
  { supplier: "NovaSolutions", opportunities: 9 },
  { supplier: "InfiniteTech", opportunities: 2 },
  { supplier: "QuantumIndustries", opportunities: 10 },
  { supplier: "EcoSystems", opportunities: 7 },
  { supplier: "NextGenInnovations", opportunities: 8 },
  { supplier: "PrimeGoods", opportunities: 7 },
  { supplier: "UltraElectronics", opportunities: 10 },
];

export const mockBenchmarkBarData = [
  {
    category: "Marketing",
    "benchmark spend": 93888,
    "benchmark spendColor": "hsl(20, 70%, 50%)",
    "peers spend": 80016,
    "peers spendColor": "hsl(259, 70%, 50%)",
  },
  {
    category: "Development",
    "benchmark spend": 79608,
    "benchmark spendColor": "hsl(20, 70%, 50%)",
    "peers spend": 82511,
    "peers spendColor": "hsl(259, 70%, 50%)",
  },
  {
    category: "Operations",
    "benchmark spend": 95361,
    "benchmark spendColor": "hsl(20, 70%, 50%)",
    "peers spend": 81739,
    "peers spendColor": "hsl(259, 70%, 50%)",
  },
  {
    category: "Sales",
    "benchmark spend": 68130,
    "benchmark spendColor": "hsl(20, 70%, 50%)",
    "peers spend": 90768,
    "peers spendColor": "hsl(259, 70%, 50%)",
  },
  {
    category: "Tech",
    "benchmark spend": 81257,
    "benchmark spendColor": "hsl(20, 70%, 50%)",
    "peers spend": 62977,
    "peers spendColor": "hsl(259, 70%, 50%)",
  },
  {
    category: "Finance",
    "benchmark spend": 90782,
    "benchmark spendColor": "hsl(20, 70%, 50%)",
    "peers spend": 59405,
    "peers spendColor": "hsl(259, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockHeatMap = [
  {
    id: "Legal",
    data: [
      { x: "23-08", y: 63527 },
      { x: "23-09", y: 29123 },
      { x: "23-10", y: 28554 },
      { x: "23-11", y: 32240 },
      { x: "23-12", y: 6886 },
      { x: "24-01", y: 39693 },
      { x: "24-02", y: 74188 },
      { x: "24-03", y: 31833 },
    ],
  },
  {
    id: "Tech",
    data: [
      { x: "23-08", y: 45278 },
      { x: "23-09", y: 20752 },
      { x: "23-10", y: 47473 },
      { x: "23-11", y: 64442 },
      { x: "23-12", y: 26103 },
      { x: "24-01", y: 15115 },
      { x: "24-02", y: 13915 },
      { x: "24-03", y: 28745 },
    ],
  },
  {
    id: "Marketing",
    data: [
      { x: "23-08", y: 71429 },
      { x: "23-09", y: 27947 },
      { x: "23-10", y: 27995 },
      { x: "23-11", y: 49872 },
      { x: "23-12", y: 73884 },
      { x: "24-01", y: 31588 },
      { x: "24-02", y: 14164 },
      { x: "24-03", y: 64955 },
    ],
  },
  {
    id: "Sales",
    data: [
      { x: "23-08", y: 6852 },
      { x: "23-09", y: 17982 },
      { x: "23-10", y: 26015 },
      { x: "23-11", y: 75325 },
      { x: "23-12", y: 30791 },
      { x: "24-01", y: 41376 },
      { x: "24-02", y: 69708 },
      { x: "24-03", y: 38486 },
    ],
  },
  {
    id: "Finance",
    data: [
      { x: "23-08", y: 20137 },
      { x: "23-09", y: 14948 },
      { x: "23-10", y: 70071 },
      { x: "23-11", y: 29427 },
      { x: "23-12", y: 53711 },
      { x: "24-01", y: 75849 },
      { x: "24-02", y: 66714 },
      { x: "24-03", y: 58975 },
    ],
  },
  {
    id: "Operations",
    data: [
      { x: "23-08", y: 67319 },
      { x: "23-09", y: 33946 },
      { x: "23-10", y: 43613 },
      { x: "23-11", y: 14806 },
      { x: "23-12", y: 29519 },
      { x: "24-01", y: 37471 },
      { x: "24-02", y: 34420 },
      { x: "24-03", y: 50355 },
    ],
  },
  {
    id: "R&D",
    data: [
      { x: "23-08", y: 25070 },
      { x: "23-09", y: 6702 },
      { x: "23-10", y: 14046 },
      { x: "23-11", y: 51223 },
      { x: "23-12", y: 23682 },
      { x: "24-01", y: 12129 },
      { x: "24-02", y: 14856 },
      { x: "24-03", y: 79749 },
    ],
  },
  {
    id: "Admin",
    data: [
      { x: "23-08", y: 11581 },
      { x: "23-09", y: 18108 },
      { x: "23-10", y: 77989 },
      { x: "23-11", y: 39033 },
      { x: "23-12", y: 38010 },
      { x: "24-01", y: 15269 },
      { x: "24-02", y: 36498 },
      { x: "24-03", y: 61868 },
    ],
  },
  {
    id: "Logistics",
    data: [
      { x: "23-08", y: 34799 },
      { x: "23-09", y: 49782 },
      { x: "23-10", y: 79325 },
      { x: "23-11", y: 43712 },
      { x: "23-12", y: 30920 },
      { x: "24-01", y: 22149 },
      { x: "24-02", y: 9767 },
      { x: "24-03", y: 19955 },
    ],
  },
  {
    id: "IT",
    data: [
      { x: "23-08", y: 70670 },
      { x: "23-09", y: 50079 },
      { x: "23-10", y: 16356 },
      { x: "23-11", y: 10823 },
      { x: "23-12", y: 52757 },
      { x: "24-01", y: 55627 },
      { x: "24-02", y: 58488 },
      { x: "24-03", y: 62142 },
    ],
  },
];

export const mockSpendingsPieChart = [
  {
    id: "Realized",
    label: "Realized",
    value: 180000,
    color: "rgb(76, 206, 172)",
  },
  {
    id: "Pending",
    label: "Pending",
    value: 1020000,
    color: "rgb(104, 112, 250)",
  },
];

export const mockActionItemsPieChart = [
  {
    id: "Realized",
    label: "Realized",
    value: 6,
    color: "rgb(76, 206, 172)",
  },
  {
    id: "Pending",
    label: "Pending",
    value: 14,
    color: "rgb(104, 112, 250)",
  },
];

// 10 vehicles (Green - color code gradiente que quede bien)-> Compliant
// 4 vehicles  (Yellow)-> Documentation expiring soon
// 1 vehicle (red) -> Documentation expired
// 4 vehicles -(Blue)> Key Data Missing
// colors={["#4cceac", "#6870fa"]}
// rgb(104, 112, 250) azul
// rgb(215, 105, 84) red
// rgb(141, 160, 203) azul2
// rgb(76, 206, 172) green

export const mockDataPieChart = [
  {
    id: "Compliant",
    label: "Compliant",
    value: 10,
    color: "rgb(76, 206, 172)", // Green
  },
  {
    id: "Doc exp. soon",
    label: "Doc expiring soon",
    value: 3,
    color: "rgb(104, 112, 250)", // Yellow
  },
  {
    id: "Doc Expired",
    label: "Doc expired",
    value: 1,
    color: "rgb(215, 105, 84)", // Red
  },
  {
    id: "KeyData missing",
    label: "Key Data missing",
    value: 4,
    color: "rgb(141, 160, 203)", // Blue
  },
];
