import Header from "../../components/Header";
import { useColorMode } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import BarChart from "../../components/BarChart";
import HeatMap from "../../components/HeatMap";
import BenchmarkBarChart from "../../components/BenchmarkBarChart";
import HorizontalBarChart from "../../components/HorizontalBarChart";
import PieChart from "../../components/PieChart";
import { mockActionItemsPieChart } from "../../data/mockData";
import { mockSpendingsPieChart } from "../../data/mockData";
import { mockActionItems, mockOpportunities } from "../../data/mockData";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import { db } from "../../firebaseConfig";
import { collection, getDocs, query } from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";

const Insights = () => {
  const { theme } = useColorMode();
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [viewVehiclesCount, setViewVehiclesCount] = useState(0);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const vehiclesSnapshot = await getDocs(
          query(collection(db, "customers", user.companyId, "vehicles")),
        );
        const vehiclesData = vehiclesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter vehicles with "View" in options[0]
        const viewVehicles = vehiclesData.filter(
          (vehicle) => vehicle.options && vehicle.options[0] === "View",
        );
        setViewVehiclesCount(viewVehicles.length);

        // Count the different values in status.text
        const statusCounts = viewVehicles.reduce((acc, vehicle) => {
          const statusText = vehicle.status?.text || "Unknown"; // Default to "Unknown" if status.text is not available
          acc[statusText] = (acc[statusText] || 0) + 1;
          return acc;
        }, {});

        setStatusCounts(statusCounts);

        setRows(viewVehicles); // If you need to display these filtered vehicles
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vehicles: ", error);
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [user.companyId]);

  const statusColorMap = {
    Compliant: "rgb(76, 206, 172)", // Green
    "Expiring Soon": "rgb(104, 112, 250)", // Yellow
    "Non Compliant": "rgb(215, 105, 84)", // Red
    "Missing Documentation": "rgb(141, 160, 203)", // Blue
  };

  const pieChartData = Object.entries(statusCounts).map(
    ([status, count], index) => ({
      id: status,
      label: status,
      value: count,
      color: statusColorMap[status] || theme.palette.primary.main,
    }),
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Coming soon..." />
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gap="20px"
        pt="20px"
        sx={{
          gridTemplateColumns: {
            sm: "repeat(1, 1fr)", // On extra-small to small screens, each box takes full width
            md: "repeat(12, 1fr)", // On medium to larger screens, use your original setup
          },
          gridAutoRows: {
            sm: "100%", // Auto-adjust the height on small screens
            md: "140px", // Fixed height on medium and large screens
          },
          gridAutoFlow: {
            sm: "row", // Ensure items flow into rows naturally
            md: "row dense", // A more dense layout for medium and larger screens
          },
        }}
      >
        {/* ROW 1 */}
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 4" },
            gridRow: { sm: "span 1", md: "span 2" },
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
          p="30px"
        >
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: theme.palette.text.default }}
            >
              Fleet Compliance
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.text.default }}>
              {viewVehiclesCount} Vehicles active
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
            height="200px"
          >
            <PieChart data={pieChartData} isDashboard={true} />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 4" },
            gridRow: { sm: "span 1", md: "span 2" },
            position: "relative",
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
          pt="30px"
          pl="30px"
          pb="0px"
        >
          <img
            src="/coming-soon.png"
            alt="Coming Soon"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "30px", // adjust size as needed
              margin: "10px",
            }}
          />
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: theme.palette.text.default }}
            >
              Potential Savings Identified
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.text.default }}>
              $ 1.200.000 Total
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
            height="200px"
          >
            <PieChart data={mockSpendingsPieChart} isDashboard={true} />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 4" },
            gridRow: { sm: "span 1", md: "span 2" },
            position: "relative",
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
          p="30px"
        >
          <img
            src="/coming-soon.png"
            alt="Coming Soon"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "30px", // adjust size as needed
              margin: "10px",
            }}
          />
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: theme.palette.text.default }}
            >
              Action Items (Current Q)
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.text.default }}>
              20 Total
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
            height="200px"
          >
            <PieChart data={mockActionItemsPieChart} isDashboard={true} />
          </Box>
        </Box>

        {/* ROW 2 */}
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 6" },
            gridRow: { sm: "span 1", md: "span 4" },
            position: "relative", // Added relative positioning
          }}
          backgroundColor={theme.palette.background.primary}
        >
          <img
            src="/coming-soon.png"
            alt="Coming Soon"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "30px", // adjust size as needed
              margin: "10px",
            }}
          />
          {/* Fixed Title Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.background.secondary,
              border:
                theme.palette.mode !== "dark"
                  ? "1px solid rgb(240, 240, 240)"
                  : "none",
              mb: "5px",
              p: "15px",
              colors: theme.palette.text.default,
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: theme.palette.text.default }}
            >
              Overview of opportunities
            </Typography>
          </Box>

          {/* Scrollable List of Opportunities */}
          <Box
            sx={{
              overflowY: "auto", // Allows vertical scrolling
              maxHeight: "calc(100% - 64px)", // Adjust maxHeight as needed, assuming 64px for the top area
              backgroundColor: theme.palette.background.primary,
              border:
                theme.palette.mode !== "dark"
                  ? "1px solid rgb(240, 240, 240)"
                  : "none",
            }}
          >
            {mockOpportunities.map((opportunity, i) => (
              <Box
                key={`opportunity-${i}`}
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: theme.palette.background.secondary,
                  mb: "5px",
                  p: "15px",
                  overflowX: "auto", // Ensure content horizontally scrolls if needed
                }}
              >
                <Typography
                  color={theme.palette.text.default}
                  variant="h6"
                  fontWeight="bold"
                  pr="5px"
                >
                  {opportunity.bold}
                </Typography>
                <Typography color={theme.palette.text.default}>
                  {opportunity.desc}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 6" },
            gridRow: { sm: "span 1", md: "span 4" },
            position: "relative",
          }}
          backgroundColor={theme.palette.background.primary}
        >
          <img
            src="/coming-soon.png"
            alt="Coming Soon"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "30px", // adjust size as needed
              margin: "10px",
            }}
          />
          {/* Fixed Title Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.background.secondary,
              border:
                theme.palette.mode !== "dark"
                  ? "1px solid rgb(240, 240, 240)"
                  : "none",
              mb: "5px",
              p: "15px",
              colors: theme.palette.text.default,
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: theme.palette.text.default }}
            >
              Action items
            </Typography>
          </Box>
          <Box
            sx={{
              overflowY: "auto", // Allows vertical scrolling
              maxHeight: "calc(100% - 64px)", // Adjust maxHeight as needed, assuming 64px for the top area
              backgroundColor: theme.palette.background.primary,
              border:
                theme.palette.mode !== "dark"
                  ? "1px solid rgb(240, 240, 240)"
                  : "none",
            }}
          >
            {mockActionItems.map((actionItem, index) => (
              <React.Fragment key={index}>
                <Box
                  display="flex"
                  alignItems="center"
                  backgroundColor={theme.palette.background.secondary}
                  border={
                    theme.palette.mode !== "dark"
                      ? "1px solid rgb(240, 240, 240)"
                      : "none"
                  }
                  mb="5px"
                  p="15px"
                >
                  <Box display="flex" alignItems="center">
                    <Typography color={theme.palette.text.default} pr="10px">
                      Owned by
                    </Typography>
                    <Typography
                      color={theme.palette.text.default}
                      variant="h6"
                      fontWeight="bold"
                    >
                      {actionItem.owner}
                    </Typography>
                  </Box>
                </Box>
                {actionItem.actions.map((innerAction, innerIndex) => (
                  <Box
                    key={`${index}-${innerIndex}`} // Assuming innerAction has no unique identifier
                    display="flex"
                    alignItems="center"
                    backgroundColor={theme.palette.background.secondary}
                    border={
                      theme.palette.mode !== "dark"
                        ? "1px solid rgb(240, 240, 240)"
                        : "none"
                    }
                    mb="5px"
                    p="12px"
                    pl="20px"
                  >
                    <Box display="flex" alignItems="center" ml="30px">
                      {innerAction.status === "done" ? (
                        <CheckCircleOutlineOutlinedIcon
                          style={{ color: "#4cceac" }}
                        />
                      ) : innerAction.status === "in progress" ? (
                        <LoopOutlinedIcon style={{ color: "#6870fa" }} />
                      ) : (
                        <HourglassEmptyOutlinedIcon
                          style={{ color: "rgb(215, 105, 84)" }}
                        />
                      )}
                    </Box>
                    <Box pl="20px" display="flex" alignItems="center">
                      <Typography color={theme.palette.text.default}>
                        {innerAction.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 6" },
            gridRow: { sm: "span 1", md: "span 2" },
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: theme.palette.grey[100], padding: "30px 30px 0 30px" }}
          >
            Costs over time
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 6" },
            gridRow: { sm: "span 1", md: "span 2" },
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: theme.palette.grey[100], padding: "30px 30px 0 30px" }}
          >
            Price variation per category
          </Typography>
          <Box height="300px" mt="-20px">
            <HeatMap isDashboard={true} />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 6" },
            gridRow: { sm: "span 1", md: "span 2" },
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: theme.palette.grey[100], padding: "30px 30px 0 30px" }}
          >
            Benchmark cost comparison
          </Typography>
          <Box height="250px" mt="-20px">
            <BenchmarkBarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: { sm: "span 1", md: "span 6" },
            gridRow: { sm: "span 1", md: "span 2" },
          }}
          backgroundColor={theme.palette.background.secondary}
          border={
            theme.palette.mode !== "dark"
              ? "1px solid rgb(240, 240, 240)"
              : "none"
          }
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: theme.palette.grey[100], padding: "30px 30px 0 30px" }}
          >
            # Opportunities per supplier
          </Typography>
          <Box height="250px" mt="-20px">
            <HorizontalBarChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Insights;
