import { ResponsiveHeatMap } from "@nivo/heatmap";
import { useTheme } from "@mui/material";
import { useColorMode } from "../theme";
import { mockHeatMap as data } from "../data/mockData";
import { format } from "d3-format";

const HeatMap = ({ isCustomLineColors = false, isDashboard = false }) => {
  const { theme } = useColorMode();

  return (
    <ResponsiveHeatMap
      data={data}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: theme.palette.text.secondary,
            },
          },
          legend: {
            text: {
              fill: theme.palette.text.secondary,
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.text.secondary,
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.text.secondary,
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.text.secondary,
          },
        },
        tooltip: {
          container: {
            color: theme.palette.primary[500],
          },
        },
      }}
      margin={{ top: 80, right: 10, bottom: 60, left: 90 }}
      valueFormat={(value) => format(">-.2s")(value)}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        legend: "",
        legendOffset: 46,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
      }}
      // colors={{
      //   type: 'diverging',
      //   colors:['rgb(198,34,40)', 'rgb(203,57,54)', 'rgb(209,81,69)',
      //     'rgb(215,105,84)', 'rgb(220,128,99)', 'rgb(226,152,114)',
      //     'rgb(232,176,129)', 'rgb(237,199,144)', 'rgb(243,223,159)',
      //     'rgb(249,247,174)', 'rgb(232,240,165)', 'rgb(217,234,157)',
      //     'rgb(201,228,148)', 'rgb(185,221,140)', 'rgb(169,215,131)',
      //     'rgb(153,209,123)', 'rgb(137,202,114)', 'rgb(121,196,106)',
      //     'rgb(105,190,98)'],
      //     minValue: 0,
      //     maxValue: 100000,
      //     divergeAt: 0.5
      //   }
      // }
      colors={{
        type: "diverging",
        scheme: "oranges",
        //minValue: 0,
        //maxValue: 100000,
        //divergeAt: 0.5
      }}
      emptyColor="#555555"
      legends={[]}
      tooltipFormat={(value) => format(">-.2s")(value)}
    />
  );
};

export default HeatMap;
